<template>
    <div>
        <div class="header">
            <span class="header_txt">店铺列表</span>
        </div>
        <div class="header_box">
            <div class="left">
                <Input v-model="storePrams.keywords" type="text" placeholder="请输入内容" />
                <button class="header_btn" @click="search">搜索</button>
                <button class="header_btn" @click="download" style="width: 180px;margin-left: 10px;">点击下载APP</button>
            </div>
            <div class="right">
                <button class="header_btn" @click="addStore">添加店铺</button>
            </div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable">
                <template slot-scope="{row}" slot="logo">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center;">
                        <img v-if="row.logo" :src="row.logo.path" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 50px;background-color: #f5f7fa;color:#c0c4cc;line-height:50px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{row}" slot="type">
                    <div style="display:flex;align-items:center;justify-content:space-around;">
                        <span class="table_span" @click="editStore(row)">编辑</span>
                        <span class="table_span" @click="request(row)">申请码</span>
                        <span class="table_span" @click="deletes(row)" style="color:#f00">删除</span>
                    </div>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-total show-elevator @on-change="pageChange"/>
        </div>
        <!-- 需要生成海报的内容 -->
        <div id="poster" ref="poster">
            <div class="poster-title">
                <div>门店名称：{{posterData.name}}</div>
                <div>职位角色：{{posterData.roleName}}</div>
            </div>
            <div class="poster-box"><img :src="posterData.qrCode" class="poster-img" alt="加载错误"></div>
        </div>
        <!-- 申请码弹框 -->
        <Modal v-model="requestModal" title="员工申请码" width="30%" @on-cancel="handleCancel">
            <div>
                <div class="modal-text">加入进来，开启智能化之旅</div>
                <div class="modal-title">你的角色：</div>
                <div class="modal-content">
                    <div v-for="(item,index) in codeList" :key="index" :class="itemActive == index ? 'modal-item-active' : 'modal-item'" @click="getModalItem(index)">{{item.name}}</div>
                </div>
                <!-- <img :src="posterImage" alt="生成的海报"> -->
                <div v-if="modalButtonVal" class="modal-foot"><Button type="primary" @click="downloadCode">生成</Button></div>
            </div>
            <div slot="footer"></div>
        </Modal>
        <!-- 添加店铺弹框 -->
        <Modal v-model="shopListModal" title="店铺信息" width="50%">
            <div>
                <Form ref="shopListForm" :model="shopListForm" :rules="shopListFormRules" :label-width="80">
                    <FormItem prop="name" label="店铺名称">
                        <Input type="text" v-model="shopListForm.name" style="width: 300px;"></Input>
                    </FormItem>
                    <FormItem prop="account" label="店铺账号">
                        <Input type="text" v-model="shopListForm.account" style="width: 300px;"></Input>
                    </FormItem>
                    <FormItem prop="city_name" label="省市区">
                        <CitySelect @select_city="select_city" :default_citys="city_numbers"></CitySelect>
                    </FormItem>
                    <FormItem prop="address" label="店铺地址">
                        <Input v-model="shopListForm.address" style="width: 300px" />
                    </FormItem>
                    <FormItem prop="logo_id" label="店铺logo">
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="logoSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                            <div v-if="shopListForm.logo_id" class="form_upload">
                                <img :src="shopListForm.logo.path" alt="" class="form_img">
                            </div>
                            <div v-else class="form_upload">
                                <Icon class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="客服电话">
                        <Input v-model="shopListForm.telephone" style="width: 300px" />
                    </FormItem>
                    <FormItem label="店铺简介">
                        <Input v-model="shopListForm.intro" maxlength="500" show-word-limit type="textarea" placeholder="请输入店铺简介" style="width: 300px" />
                    </FormItem>
                    <FormItem label="简介图片">
                        <div class="form_mask">
                            <div v-for="(item, index) of shopListForm.intros" class="mask_upload" :key="index">
                                <img :src="item.img.path" alt="" class="mask_img"/>
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click="maskDelete(item,index)"/>
                                    </div>
                                </a>
                            </div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="pictureSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadImg"
                            >
                                <div class="form_upload">
                                    <Icon class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="shopListModalF">取消</Button>
                <Button type="primary" @click="shopListModalT('shopListForm')">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import {CitySelect} from "@/components/mainLayout"
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
import {shopList, shopCreate, shopDel, otherDelimg, shopJobList} from '@/api/index'
    export default {
        components:{
            CitySelect
        },
        data() {
            return {
                posterData:{
                    name: '',
                    roleName: '',
                    qrCode: ''
                },
                posterImage: null,
                codeList:[],
                requestModal: false,//员工申请码
                appUrl:'',
                uploadImg: config.apiUrl + "/admin/other/uploads",
                headers: {
                    "meiye-admin-api": getCookies("meiye-admin-api"),
                },
                //table
                liveTable: [
                    {title: '店铺名称',key: 'name',align:'center'},
                    {title: '店铺logo',slot: 'logo',align:'center'},
                    {title: '店铺地址',key: 'address',align:'center'},
                    {title: '店铺账号',key: 'account',align:'center'},
                    {title: '创建时间',key: 'created_at',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [],
                //page
                total: 10,
                storePrams: {
                    page: 1,
                    limit: 30,
                    keywords:''
                },
                shopListModal: false,
                shopListForm: {
                    name: '',
                    account: '',
                    logo_id: '',
                    address: '',
                    city_name: '',
                    prov: '',
                    city: '',
                    area: '',
                    // password: '', //密码 传则会修改密码
                    telephone: '',
                    intro: '',
                    intro_id: '',
                    // id: '',
                    intros:[]
                },
                city_numbers:[],
                shopListFormRules: {
                    name: [
                        { required: true, message: '请输入店铺名称', trigger: 'blur' }
                    ],
                    account: [
                        { required: true, message: '请输入店铺账号', trigger: 'blur' }
                    ],
                    city_name: [
                        { required: true, message: '请选择省市区', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请输入店铺地址', trigger: 'blur' }
                    ],
                    logo_id: [
                        { required: true, message: '请上传店铺logo', trigger: 'blur' }
                    ],
                },
                itemActive: -1,
                modalButtonVal: false
            }
        },
        created(){
            this.shopList()
        },
        mounted(){
            this.appUrl = getCookies('appUrl')
        },
        methods: {
            handleCancel() {
                this.itemActive = -1
                this.modalButtonVal = false
            },
            getModalItem(i){
                this.itemActive = i
                this.modalButtonVal = true
                this.posterData.qrCode = this.codeList[i].applet_code_url
                this.posterData.roleName = this.codeList[i].name
            },
            // 将Base64字符串转换为Blob对象
            dataURLtoBlob(dataurl) {
                var arr = dataurl.split(',');
                var mime = arr[0].match(/:(.*?);/)[1];
                var bstr = atob(arr[1]);
                var n = bstr.length;
                var u8arr = new Uint8Array(n);
                while(n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], {type:mime});
            },
            // 生成海报（申请码）
            async downloadCode(){
                try {
                    const canvas = await html2canvas(this.$refs.poster);
                    this.posterImage = canvas.toDataURL('image/png');
                } catch (error) {
                    console.error('生成海报失败:', error);
                }
                var a = document.createElement("a"); 
                var event = new MouseEvent("click"); 
                a.download = name || "photo";
                let blob = this.dataURLtoBlob(this.posterImage);
                let url = URL.createObjectURL(blob);
                a.href = url;
                a.dispatchEvent(event);
            },
            download(){
                window.open(this.appUrl);
            },
            select_city(e){
                this.city_numbers = e.city_number
                this.shopListForm.city_name = e.city_name
            },
            // 店铺列表
            shopList(){
                shopList(this.storePrams).then(res=>{
                    console.log('店铺列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            addStore(){
                this.shopListForm = {
                    name: '',
                    account: '',
                    logo_id: '',
                    address: '',
                    city_name: '',
                    prov: '',
                    city: '',
                    area: '',
                    telephone: '',
                    intro: '',
                    intro_id: '',
                    intros:[]
                },
                this.city_numbers = []
                this.shopListModal = true
                this.shopListForm.id = ''
                this.shopListForm.logo = {}
            },
            //员工申请码
            request(item){
                this.posterData.name = item.name
                this.requestModal = true
                shopJobList({id: item.id}).then(res => {
                    console.log('申请码列表', res.data)
                    if(res.data){
                        this.codeList = res.data
                    }
                })
            },
            //编辑按钮-打开编辑弹框
            editStore(e){
                console.log('eee', e)
                this.shopListForm = e
                this.city_numbers = [e.prov, e.city, e.area ]
                this.shopListModal = true
                if(!this.addLiveListForm.logo_id){
                    this.addLiveListForm.logo = {}
                }
            },
            pageChange(index) {
                this.storePrams.page = index;
            },
            //添加店铺取消按钮
            shopListModalF(){
                this.shopListModal = false
            },
            //添加店铺确认按钮
            shopListModalT(name){
                var intro_ids =[]
                this.shopListForm.intros.forEach((item) => {
                    intro_ids.push(item.img_id)
                })
                this.shopListForm.intro_id = intro_ids.join(",")
                this.shopListForm.prov = this.city_numbers[0]
                this.shopListForm.city = this.city_numbers[1]
                this.shopListForm.area = this.city_numbers[2]
                let data = {
                    name: this.shopListForm.name,
                    account: this.shopListForm.account,
                    logo_id: this.shopListForm.logo_id,
                    address: this.shopListForm.address,
                    city_name: this.shopListForm.city_name,
                    prov: this.shopListForm.prov,
                    city: this.shopListForm.city,
                    area: this.shopListForm.area,
                    telephone: this.shopListForm.telephone,
                    intro: this.shopListForm.intro,
                    intro_id: this.shopListForm.intro_id,
                    id: this.shopListForm.id
                }
                shopCreate(data).then(res => {
                    this.shopListModal = false
                    this.shopList()
                    this.$Message.success(res.msg);
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            // 删除店铺
            deletes(item){
                shopDel({id:item.id}).then(res => {
                    this.shopList()
                    this.$Message.success(res.msg);
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            // 删除图片
            maskDelete(item,i){
                this.shopListForm.intros.splice(i,1)
                // otherDelimg({id:item.id}).then(res => {
                //     this.$Message.success(res.msg);
                // })
            },
            //搜索按钮-调接口
            search(){
                this.shopList()
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress() {
                const msg = this.$Message.loading({
                    content: "上传中...",
                    duration: 0,
                });
                setTimeout(msg, 3000);
            },
            // 简介图片
            pictureSuccess(e) {
                this.shopListForm.intros.push({'img_id' : e.data.id, 'img':{'path':e.data.path}});
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 店铺logo
            logoSuccess(e) {
                this.shopListForm.logo_id = e.data.id
                this.shopListForm.logo = e.data
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
        },
    }
</script>

<style lang="scss" scoped>
#poster{
    display: flex;
    flex-direction: column;
    align-content: center;
    background-image: url("../../../../assets/poster_bg.png");
    width: 400px;
    min-height: 400px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
}
.poster-title{
    width: 350px;
    margin: 25px;
    background-color: #6097fc;
    padding: 20px;
    border-radius: 20px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}
.poster-box{
    width: 350px;
    height: 350px;
    margin: 0 25px 50px 25px;
    background-image: url("../../../../assets/poster_box.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.poster-img{
    width: 300px;
    height: 300px;
    border-radius: 10px;
    z-index: 9;
}
.modal-text{
    font-size: 16px;
    color: #606266;
    line-height: 40px;
}
.modal-title{
    font-weight: 600;
    color: #606266;
    font-size: 14px;
    line-height: 40px;
}
.modal-content{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}
.modal-item{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #f5f7fe;
    font-size: 20px;
    line-height: 100px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #f5f7fe;
    cursor: pointer;
    color: #606266;
}
.modal-item-active{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #f5f7fe;
    font-size: 20px;
    line-height: 100px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #606266;
    border: 1px solid #6f8fef;
}
.modal-foot{
    text-align: center;
    margin-top: 30px;
}
.form_mask .form_upload:hover{
    border: 1px dashed #409eff;
}
.form_mask{
    display:flex;
    flex-wrap:wrap;
    width: 100%;
}
.mask_upload{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    position: relative;
}
.mask_img{
    width: 100px;
    height:100px;
    border-radius: 10px;
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background: rgba(101, 101, 101, 0.6);
    color: #ffffff;
    opacity: 0;
    border-radius: 10px;
}
.mask .mask_icon {
    font-size: 20px;
}
.mask_upload a:hover .mask {
    opacity: 1; 
    text-align: center;  
    height: 100px;
    line-height: 100px;        
}
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.left{
    display: flex;
    align-items: center;
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.table_span{
    color: #409eff;
    cursor: pointer;
}
::v-deep .ivu-input.ivu-input-default{
    height: 40px;
}
.form_upload{
    width: 100px;
    height: 100px;
    border: 1px dashed #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.form_img{
    width: 100px;
    height:100px;
    border-radius: 10px;
    border:1px dashed #ccc;
}
.form_img:hover{
    border: 1px dashed #409eff;
}

//表头背景颜色
::v-deep .ivu-table-header thead tr th {
  background: #fff !important;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
  color: #909395 !important;
}
</style>